import { Orders, Promotions } from 'cs-connect'
import React, { useEffect, useState } from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native'
import TextField from './utils/TextField'
import Progress from './utils/Progress'
import Grid from '@mui/material/Grid';
import lodash from 'lodash'
import Button from './components/Button'
import CheckBox from './components/CheckBox'
import { Alert } from './utils/Alert.web'
import { useHistory, Link, useParams } from 'react-router-dom'
import moment from 'moment'
import {
	ChannelOptions,
	StatusOptions
} from 'cs-connect/lib/Promotions'
import {
	createTimeStamp
} from 'firestar/lib/Utils'
import firebase from 'firebase/compat/app';
import { exportListToCSV } from 'cs-connect/lib/Utils'
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { SectionPanel } from './utils/SectionPanel'
import CheckPermission from './utils/CheckPermission'
import cloneDeep from 'lodash/cloneDeep'
import { usePromotionsContext } from './containers/PromotionsContext'
import { Timestamp } from 'firebase/firestore'
import firestar from 'firestar'
import { useSettingsContext } from './containers/SettingsContextProvider'

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'

function defaultPromotion() {

	const now = new Date()
	const endDate = new Date(now.getTime() + 60 * 60 * 24 * 1000);
	return {
		status: "draft",
		startDate: createTimeStamp(now.getTime() / 1000),
		endDate: createTimeStamp(endDate.getTime() / 1000),
		name: ''
	} as Promotions.Promotion
}

export default function CreatePromotion() {

	const history = useHistory()
	const [showLoader, setShowLoader] = useState(true)
	const { promotionId } = useParams() as { promotionId?: string }
	const [clone] = useState(new URLSearchParams(window.location.search).get('clone'))
	const { linkedCasinoId } = useSettingsContext()
	const { fetchPromotion, addPromotion, updatePromotion } = usePromotionsContext()
	const casinoId = linkedCasinoId

	const [promotion, setPromotion] = useState<Promotions.Promotion>()
	const [savedPromotion, setSavedPromotion] = useState<Promotions.Promotion>()

	useEffect(() => {
		if (promotionId != null) {
			getPromotion(promotionId)
		}
	}, [promotionId])

	useEffect(() => {

		if (clone != null && (clone ?? '').length > 0 && casinoId != null) {
			setShowLoader(true)
			Promotions.getPromotion(casinoId, clone).then((response) => {
				onChange({
					name: `Clone of ${response.name}`,
					acceptVouchers: response.acceptVouchers,
					validateLocation: response.validateLocation,
					allowTiers: response.allowTiers,
					enableMemberByDefault: response.enableMemberByDefault,
					disableClaim: response.disableClaim,
					channels: response.channels,
					limitClaims: response.limitClaims,
					allowMultipleClaims: response.allowMultipleClaims,
					allowShippingAddress: response.allowShippingAddress,
					thumbnail: response.thumbnail
				})
				setShowLoader(false)
			})
				.catch((error) => {
					setShowLoader(false)
					Alert.alert('Unable to clone', String(error))
				})
		}
	}, [clone])

	if (promotion == null) {
		return <Progress />
	}

	return (
		<Grid xs={12} sm={8} md={8} lg={7} xl={6}>
			{showLoader == true && <Progress />}
			<View
				style={{
					padding: 15,
					width: '80%'
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						//  marginVertical: 15,
						alignItems: 'center',
					}}>
					<Text style={{ fontSize: 25, }}>Promotion Manager</Text>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: 'center',
						}}>
						{promotion.id == null && (
							<Text
								style={{
									color: "gray",
									fontStyle: "italic",
									marginRight: 10,
								}}>
								Save this promotion in order to add gifts and members.
							</Text>
						)}
						<div>
							<div style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center'
							}}>
								<CheckPermission minimumRole="moderator">
									{promotion.status == 'published' && <Button
										titleStyle={{
											fontWeight: "bold",
											fontSize: 12,
										}}
										type='outlined'
										disabled={promotion.name.trim().length === 0}
										style={{
											margin: 5,
											width: 180,
										}}
										onPress={onClickSwitchDraft}
										title={"SWITCH TO DRAFT"} />}
									<Button
										titleStyle={{
											fontWeight: "bold",
											fontSize: 12,
										}}
										disabled={promotion.name.trim().length === 0}
										containerStyle={{
											margin: 5,
											width: 150,
										}}
										onPress={onClickSave}
										title={"SAVE"} />
								</CheckPermission>
								<CheckPermission minimumRole="editor">
									<Link to={`/promotions/${promotionId}/bulk-order`} style={{
										marginLeft: 5
									}}>
										<Button
											titleStyle={{
												fontWeight: "bold",
												fontSize: 12,
											}}
											disabled={promotion.name.trim().length === 0}
											containerStyle={{
												width: 150,
											}}
											title={"CLAIM GIFT"} />
									</Link>
								</CheckPermission>
							</div>
						</div>
						<CheckPermission minimumRole="admin">
							{promotion.createdAt != null && promotion.status !== "published" && (
								<Button
									onPress={onPressPublish}
									type="outlined"
									style={{
										width: 150,
									}}
									titleStyle={{
										fontWeight: "bold",
										fontSize: 12,
									}}
									title="PUBLISH"
								/>
							)}
						</CheckPermission>
					</View>
				</View>
				<br/>
				<View style={{
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<b>Promo Setup</b>
					<View style={{ 
						height: 2, 
						flex: 1, 
						backgroundColor: 'black',
						marginLeft: 20
					}}/>
				</View>
				<br />
				<View style={{
					flexDirection: 'row',

				}}>
					<CheckPermission minimumRole="admin" type={'fade'}>
						<TextField
							style={{
								flex: 0.5,
								marginRight: 50
							}}
							onChangeText={(value) => onChangeValue("name", value)}
							value={promotion?.name}
							placeholder="Eg: Gift of the Week, Birthday Month"
							label="Promotion Name"
						/>
					</CheckPermission>
					<SectionPanel
						title="Status"
						render={() => {
							return renderStatus(promotion);
						}}
					/>
				</View>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<View
						style={{
							marginTop: 25,
						}}>
						<CheckPermission minimumRole="moderator" type='fade'>
							<SectionPanel
								title="Start Date"
								render={() => {
									let startDate =
										promotion.startDate as firebase.firestore.Timestamp;
									let date = startDate?.toDate?.() ?? startDate ?? new Date();
									const dateString = moment(date).format(DATE_TIME_FORMAT);

									return (
										<div>
											<input
												style={{
													padding: 5,
													width: 220,
												}}
												min={moment(new Date()).format(DATE_TIME_FORMAT)}
												onChange={(event) => {
													let date = moment(
														event.target.value,
														DATE_TIME_FORMAT
													).toDate();
													onChangeValue("startDate", date);
												}}
												value={dateString}
												type="datetime-local"
											/>
											<span>&nbsp;&nbsp;{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
										</div>
									);
								}}
							/>
							<SectionPanel
								title="End Date"
								render={() => {
									let endDate =
										promotion.endDate as firebase.firestore.Timestamp;
									let date = endDate?.toDate?.() ?? endDate ?? new Date();
									const dateString = moment(date).format(DATE_TIME_FORMAT);
									return (
										<div>
											<input
												style={{
													padding: 5,
													width: 220,
												}}
												min={moment(new Date()).format(DATE_TIME_FORMAT)}
												onChange={(event) => {
													const date = moment(
														event.target.value,
														DATE_TIME_FORMAT
													).toDate();
													onChangeValue("endDate", createTimeStamp(date.getTime() / 1000));
												}}
												value={dateString}
												type="datetime-local"
											/>
											<span>&nbsp;&nbsp;{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
										</div>
									);
								}}
							/>
						</CheckPermission>
						<CheckPermission minimumRole="admin" type='fade'>
							<SectionPanel
								title="Options"
								render={() => {
									return (
										<>
										<div style={{
											columnCount: 2,
										}}>
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white"
												}}
												onPress={() => {
													const value = !(
														promotion.enableMemberByDefault ?? true
													);
													onChangeValue("enableMemberByDefault", value);
												}}
												title="Players Enabled by Default"
												checked={promotion.enableMemberByDefault ?? true} />
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white",
												}}
												onPress={() => onChangeValue("validateLocation", !(promotion.validateLocation ?? true))}
												title="Enable Geolocation Check"
												checked={promotion.validateLocation ?? true} />
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white",
												}}
												onPress={() =>
													onChangeValue(
														"allowMultipleClaims",
														!(promotion.allowMultipleClaims ?? false)
													)
												}
												title="Allow Multiple Gift Claims"
												checked={promotion.allowMultipleClaims ?? false} />
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white",
												}}
												onPress={() => {
													const value = !(
														promotion.allowShippingAddress ?? false
													);
													onChangeValue("allowShippingAddress", value);
												}}
												title='Turn On "Gift a Friend"'
												checked={promotion.allowShippingAddress ?? false} />
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white",
												}}
												onPress={() => {
													const value = !(
														promotion.disableClaim ?? false
													);
													onChangeValue("disableClaim", value);
												}}
												title="Disable Gift Claim - Browser Mode"
												checked={promotion.disableClaim ?? false} />
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white",
												}}
												onPress={() => {
													const value = !(promotion.allowTiers ?? false);
													onChangeValue("allowTiers", value);
												}}
												title="Enable Player Tiers"
												checked={promotion.allowTiers ?? false}/>
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white",
												}}
												onPress={() => {
													const value = !(promotion.redeemPoints ?? false);
													onChangeValue("redeemPoints", value);
												}}
												title="Points Shopping - Redeem Points"
												checked={promotion.redeemPoints ?? false} />
											<CheckBox
												containerStyle={{
													marginLeft: 0,
													backgroundColor: "white",
												}}
												onPress={() => {
													const value = !(promotion.resolveMembers ?? false);
													onChangeValue("resolveMembers", value);
												}}
												title="Points Shopping - Resolve Players By Tier"
												checked={promotion.resolveMembers ?? false} />
											{promotion.resolveMembers === true && (
												<TextField
													style={{
														marginTop: '0.5em'
													}}
													value={promotion.resolveMembersQuery}
													label='Additional Query Parameters'
													placeholder='Eg: tier=gold'
													onChangeText={(value) => {
														onChangeValue("resolveMembersQuery", value);
													}} />
											)}
										</div>
										</>
									);
								}} />
							<br />
							</CheckPermission>
							<CheckPermission minimumRole="moderator" type='fade'>
								<Link to={`/promotions/${promotionId}/select-gifts`}>Select Gifts</Link>
								<br />
							</CheckPermission>
							<CheckPermission minimumRole="admin" type='fade'>
							<SectionPanel
								title="Channels"
								render={() => {
									return (
										<div>
											{Object.keys(ChannelOptions).map((key: any) => (
												<div>
													<CheckBox
														containerStyle={{
															marginLeft: 0,
															backgroundColor: "white",
														}}
														onPress={() => {
															const channels = promotion.channels ?? []
															const index = channels.findIndex((c) => c === key)
															if (index >= 0) {
																channels.splice(index, 1)
															} else {
																channels.push(key)
															}
															onChangeValue('channels', channels)
														}}
														title={ChannelOptions[key]}
														checked={promotion.channels?.includes(key)} />
												</div>
											))}
										</div>
									)
								}} />
							<SectionPanel
								title="Advanced"
								render={() => {
									return (
										<div style={{
											columnCount: 2
										}}>
											<div>
												<CheckBox
													containerStyle={{
														marginLeft: 0,
														backgroundColor: "white",
													}}
													onPress={() => {
														onChangeValue(
															"limitClaims",
															promotion.limitClaims == null ? 100 : null
														);
													}}
													title="Limit Claims"
													checked={promotion.limitClaims != null}
												/>
												{promotion.limitClaims != null && (
													<TextField
														label="Number Of Claims"
														value={promotion.limitClaims}
														onChangeText={(value) => {
															const number = Number(value.trim());
															if (!isNaN(number)) {
																onChangeValue("limitClaims", number);
															}
														}}
													/>
												)}
											</div>
											<View
												style={{
													paddingVertical: 2.5,
												}}>
												<CheckBox
													containerStyle={{
														marginLeft: 0,
														backgroundColor: "white",
													}}
													onPress={() =>
														onChangeValue(
															"acceptVouchers",
															!(promotion.acceptVouchers ?? false)
														)
													}
													title="Enable Vouchers"
													checked={promotion.acceptVouchers ?? false} />
												<Text
													style={{
														marginTop: 5,
													}}>
													{(promotion.acceptVouchers ?? false) == true && (
														<Link
															style={{
																textDecoration: "none",
															}}
															to={`/promotions/${promotion.id}/vouchers`}>
															Edit Vouchers →
														</Link>
													)}
												</Text>
											</View>
										</div>
									);
								}}
							/>
						</CheckPermission>
					</View>
					<View>
						<SectionPanel
							title="Thumbnail"
							render={() => {
								return (
									<ImageUploading
										value={[
											{
												dataURL: promotion.thumbnail ?? "",
											},
										]}
										onChange={onSelectCoverImage}>
										{({ onImageUpload, onImageRemove }) => {
											return (
												<View
													style={{
														alignItems: "center",
													}}>
													<Image
														style={{
															width: 300,
															height: 330,
															borderWidth: 1,
															borderColor: "#dddddd",
															borderRadius: 2,
														}}
														resizeMode="contain"
														source={{
															uri: promotion.thumbnail ?? "",
														}}
													/>
													<CheckPermission minimumRole="admin" type='hide'>
														<Button
															type="text"
															titleStyle={{
																fontSize: 12,
															}}
															containerStyle={{
																marginTop: 10,
															}}
															title={
																promotion.thumbnail != null
																	? "Remove Image"
																	: "Upload Image"
															}
															onPress={() => {
																if (promotion.thumbnail) {
																	onImageRemove(0);
																} else {
																	onImageUpload();
																}
															}}
														/>
													</CheckPermission>
												</View>
											);
										}}
									</ImageUploading>
								);
							}}
						/>
					</View>
				</View>
				<CheckPermission minimumRole="editor" type='hide'>
					{promotion.createdAt != null && (
						<SectionPanel
							title=""
							render={() => {
								return (
									<>
										<View style={{
											flexDirection: 'row',
											justifyContent: 'center',
											alignItems: 'center'
										}}>
											<b>Player Management</b>
											<View style={{
												height: 2,
												flex: 1,
												backgroundColor: 'black',
												marginLeft: 20,
											}} />
										</View>
										<br />
										<View
											style={{
												paddingVertical: 10,
												flexDirection: 'row',
											}}>
											<Link style={{
												marginRight: 50,
											}} to={`/promotions/${promotionId}/members`}>Upload/Manage Players</Link>
											{promotionId != null && (
												<TouchableOpacity onPress={onPressExport}
													style={{
														marginRight: 50,
													}}>
													<Link to={`#`}>Export Promotion Claim Data</Link>
												</TouchableOpacity>
											)}
											{promotionId != null && (
												<div>
													<a target={"_blank"} href={`https://app.infigifts.com/casinos/${casinoId}/promotions/${promotion.id}`}>
														Open on Web
													</a>
												</div>
											)}
										</View>
									</>
								);
							}}
						/>
					)}
				</CheckPermission>
			</View>
		</Grid>
	);

	function onSelectCoverImage(value?: ImageListType) {
		if ((value ?? []).length > 0) {
			onChangeValue('thumbnail', value![0].dataURL ?? null)
		} else {
			onChangeValue('thumbnail', null)
		}
	}


	function onChange(changes: Partial<Promotions.Promotion>) {
		const __promotion = { ...promotion } as Promotions.Promotion
		setPromotion({
			...__promotion,
			...changes
		})
	}

	function onChangeValue(path: string, value: any) {
		const __promotion = { ...promotion } as Promotions.Promotion
		lodash.set(__promotion, path, value)
		setPromotion(__promotion)
	}

	async function onPressPublish() {
		if (casinoId == null || promotion == null) return
		setShowLoader(true)
		try {
			if (promotion.name.trim().length == 0) {
				throw 'Enter a name for the promotion'
			}
			await updatePromotion(promotion.id, {
				...promotion,
				status: 'published'
			})
			history.push('/promotions')
		} catch (error) {
			Alert.alert('Error Creating Promotion', String(error))
		}
		setShowLoader(false)
	}

	async function onClickSwitchDraft() {

		setShowLoader(true)
		try {
			if (promotion?.id) {
				await updatePromotion(promotion.id, {
					...promotion,
					status: 'draft'
				})
			}
		} catch (error) {
			console.log('Error Switching Draft : ', error)
		}
		setShowLoader(false)
	}

	async function onClickSave() {


		function getDiff(newObject: any, oldObject: any) {
			const oldKeys = getAllKeys(oldObject)
			const newKeys = getAllKeys(newObject)

			const missingKeys: string[] = lodash.difference(oldKeys, newKeys)
			const change = {}
			missingKeys.forEach((key) => {
				const newValue = lodash.get(newObject, key) ?? null
				lodash.set(change, key, newValue)
			})

			const addedKeys: string[] = lodash.difference(newKeys, oldKeys)
			addedKeys.forEach((key) => {
				const newValue = lodash.get(newObject, key) ?? null
				lodash.set(change, key, newValue)
			})

			const intersectKeys: string[] = lodash.intersection(newKeys, oldKeys)
			intersectKeys.forEach((key) => {
				const oldValue = lodash.get(oldObject, key) ?? null
				const newValue = lodash.get(newObject, key) ?? null
				if (lodash.isEqual(oldValue, newValue) === false) {
					lodash.set(change, key, newValue)
				}
			})

			return change
		}

		function getAllKeys(object: any, prefix?: string): string[] {
			const firestore = firestar.getFirestore()
			return Object.keys(object).reduce<string[]>((previous, key) => {

				const value = object[key]
				const relativeKey = prefix != null ? (prefix + '.' + key) : key

				if (typeof value === 'string' || value instanceof String) {

					return [...previous, relativeKey]

				} else if (typeof value == "boolean") {

					return [...previous, relativeKey]

				} else if (typeof value == "number") {

					return [...previous, relativeKey]

				} else if (value == null) {

					return [...previous, relativeKey]

				} else if (value === undefined) {

					return [...previous, relativeKey]

				} else if (value instanceof Array) {

					return [...previous, relativeKey]

				} else if (value instanceof firestore.Timestamp) {

					return [...previous, relativeKey]

				} else if (value instanceof firestore.GeoPoint) {

					return [...previous, relativeKey]

				} else if (typeof value === "object") {

					return [...previous, ...getAllKeys(value, relativeKey)]

				}
				return [...previous]
			}, [])
		}

		const flattenObject = (
			obj: any,
			prefix = '',
			res = {} as any,
			ignoreKeys = [],
			separator = '.'
		) => {
			const firestore = firestar.getFirestore()
			return Object.entries(obj).reduce((r, [key, val]) => {
				const k = `${prefix}${key}`
				if (val != null) {
					if (typeof val === 'object') {
						if (val instanceof Array) {
							res[k] = val
						} else if (val instanceof firestore.Timestamp) {
							res[k] = val
						} else if (val instanceof firestore.GeoPoint) {
							res[k] = val
						} else {
							flattenObject(val, `${k}${separator}`, r)
						}
					} else {
						const result = ignoreKeys.find((key) => {
							return k.startsWith(key)
						})
						if (result == null) {
							res[k] = val as any
						}
					}
				}
				return r
			}, res)
		}

		if (casinoId == null || promotion == null) return

		setShowLoader(true)

		const __updatingPromotion = {
			...promotion
		} as Promotions.Promotion

		try {
			if (promotion.thumbnail && promotion.thumbnail?.startsWith('data')) {
				console.log('Uploading Thumbnail')
				const response = await Promotions.uploadThumbnail(casinoId, __updatingPromotion.id, promotion.thumbnail)
				__updatingPromotion.thumbnail = response.downloadUrl
			} else {
				__updatingPromotion.thumbnail = null
			}
		} catch (error) {
			console.log('Error Uploading Thumbnail : ', error)
		}

		try {
			if (promotion.id) {
				const changes = getDiff(__updatingPromotion, savedPromotion)

				console.log('__updatingPromotion', (__updatingPromotion.endDate as Timestamp).toDate())

				console.log('changes', changes)

				const flattenedChanges = flattenObject(changes)
				console.log('flattenedChanges', flattenedChanges)

				await updatePromotion(__updatingPromotion.id, flattenedChanges)
				getPromotion(promotion.id)
			} else {
				await addPromotion(__updatingPromotion)
			}
			history.push('/promotions')
		} catch (error) {
			Alert.alert('Error Creating Promotion', String(error))
		}

		setShowLoader(false)
	}

	async function getPromotion(promotionId: string) {
		console.log('promotionId', promotionId)
		if (promotionId === 'create') {
			setPromotion(defaultPromotion())
			setShowLoader(false)
		} else {

			try {
				const response = await fetchPromotion(promotionId)
				if(response == null) {
					throw new Error('The promotion you are requesting is not available in this casino. Please verify and try again.');
				}
				console.log('Fetched Promotion: ', response)
				setPromotion(response)
				const __savedPromotion = cloneDeep(response)
				setSavedPromotion(__savedPromotion)
				setShowLoader(false)
			} catch (error) {
				console.log('Error Fetching Promotion: ', error)
				Alert.alert('Unable to get promotion', String(error))
				history.replace('/')
			}
		}
	}

	async function onPressExport() {

		if (promotionId == null) return
		setShowLoader(true)
		try {
			const response = await Orders.getOrdersForPromotion(promotionId, {
				limit: 10000
			})
			const orders = response.data.map((order) => {

				const phoneNumber = order.member.phoneNumber ?? ''
				return {
					...order,
					trackingLink: order.overrideTrackingLink ?? order.trackingLink ?? '',
					member: {
						...order.member,
						phoneNumber: phoneNumber.substring(phoneNumber.length - 10)
					}
				}
			})
			if (orders.length == 0) {
				Alert.alert('No Orders for this promotion')
			} else {
				const fileName = `${promotion?.name}_orders_${orders.length}`
				exportListToCSV(orders, fileName, [
					'id',
					'metadata_name',
					'status',
					'userInfo_phoneNumber',
					'userInfo_uid',
					'member_name',
					'member_firstName',
					'member_lastName',
					'member_phoneNumber',
					'member_email',
					'member_cardNumber',
					'member_gender',
					'member_age',
					'member_address_street',
					'member_address_city',
					'member_address_state',
					'member_address_stateCode',
					'member_address_country',
					'member_address_zipCode',
					'casinoInfo_name',
					'product_title',
					'metadata_total_price',
					'metadata_created_at',
					'promotionInfo_name',
					'trackingLink',
					'author_displayName',
					'author_email'
				])
			}
		} catch (error) {
			console.log('Error Exporting Orders', error)
			Alert.alert('Error Exporting Orders', String(error))
		}
		setShowLoader(false)
	}

}

export function renderStatus(promotion: Promotions.Promotion) {

	const endDate = promotion.endDate as firebase.firestore.Timestamp
	return (
		<View>
			<Text>{endDate?.toDate ? (endDate.toDate().getTime() < (new Date()).getTime() ? (
				<Text style={{
					flex: 1,
					backgroundColor: '#dddddd',
					color: 'black',
					paddingHorizontal: 15,
					paddingVertical: 2,
					borderRadius: 5
				}}>Expired</Text>
			) : (
				<Text style={{
					flex: 1,
					color: 'white',
					paddingHorizontal: 15,
					paddingVertical: 2,
					borderRadius: 5,
					backgroundColor: 'green'
				}}>{StatusOptions[promotion.status]}</Text>
			)) : '--'}</Text>
		</View>
	)
}

