import React, { CSSProperties } from 'react'
import MUIButton from '@material-ui/core/Button'

interface Props {
	onPress?: () => void,
	title: string,
	style?: CSSProperties,
	disabled?: boolean
	containerStyle?: CSSProperties,
	type?: 'text' | 'outlined' | 'contained'
	titleStyle?: CSSProperties
	buttonStyle?: CSSProperties
}

export default function Button(props: Props) {
	const { onPress, title, style, disabled, type } = props
	return (
		<MUIButton 
			variant={type ?? 'contained'}
			onClick={onPress} 
			color='primary'
			style={{
				background: !disabled ? (type == null ? "#186ccc" : undefined) : '#dddddd',
				color: type == null ? "white" : undefined,
				...style
			}}
			disabled={disabled}>{title}</MUIButton>
	)
}
