import { Gifts } from "cs-connect"
import { AddressType } from "cs-connect/lib/Address"
import { useEffect, useState } from "react"

export function formatAddress(address?: AddressType) {

   const components = [] as string[]
   if (address?.street) {
      components.push(address.street)
   }
   if (address?.city) {
      components.push(address.city)
   }
   if (address?.zipCode) {
      components.push(address.zipCode)
   }
   if (address?.stateCode) {
      components.push()
   }
   return `${components.join(', ')} ${address?.stateCode ?? ''}`
}

export function formatName(firstName:string, lastName: string) {

   const components = [] as string[]
   if(firstName){
      components.push(firstName)
   }
   if(lastName){
      components.push(lastName)
   }
   return components.join(' ')
}

export function convertToTypeOf(typevar, input) {
   let type: "string" |
      "number" |
      "bigint" |
      "boolean" |
      "symbol" |
      "undefined" |
      "object" |
      "function" |
      "numeric" = typevar
   switch (type) {
      case "string":
         return String.bind(null, input)();
      case "number":
         return Number.bind(null, input)();
      case "numeric":
         return Number.bind(null, input)();
      case "boolean":
         return input === "true" ? true : false;
      default:
         return input
   }
}

export function validateEmail(email) {
   return (String(email)
      .trim()
      .toLowerCase()
      .match(
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) ?? []).length > 0 ? true : false
};

export function validatePhone(phoneNumber?: string) {
   if(phoneNumber == null || phoneNumber?.length === 0) return false
   return (phoneNumber?.length > 11) && (String(phoneNumber).trim().toLowerCase().match(/^\+[1-9]{1}[0-9]/) ?? []).length > 0 ? true : false
};

export function useDebounce(value, delay) {
   // State and setters for debounced value
   const [debouncedValue, setDebouncedValue] = useState(value);

   useEffect(
      () => {
         // Set debouncedValue to value (passed in) after the specified delay
         const handler = setTimeout(() => {
            setDebouncedValue(value);
         }, delay);

         // Return a cleanup function that will be called every time ...
         // ... useEffect is re-called. useEffect will only be re-called ...
         // ... if value changes (see the inputs array below). 
         // This is how we prevent debouncedValue from changing if value is ...
         // ... changed within the delay period. Timeout gets cleared and restarted.
         // To put it in context, if the user is typing within our app's ...
         // ... search box, we don't want the debouncedValue to update until ...
         // ... they've stopped typing for more than 500ms.
         return () => {
            clearTimeout(handler);
         };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [value]
   );

   return debouncedValue;
}

export function imageForProduct(product: Gifts.ShopifyProduct) {
	const images = product.images ?? []
	let thumbnail = images.length ? images[0]?.src : undefined
	return thumbnail
}
 