import { useCasinoContext } from './containers/CasinoContextProvider'
import { useAuthContext } from './containers/AuthContextProvider'

export default function Dashboard() {

	const { claims } = useAuthContext()
	const { casino } = useCasinoContext()
	return (
		<div>
			<p>Name: {casino.name}</p>
			<p>Level: {(claims.roles ?? {})[casino.id].level}</p>
			<p>Id: {casino.id}</p>
		</div>
	)
}
