import { Casinos, Users } from 'cs-connect'
import { useEffect, useMemo, useState } from 'react'
import { View, FlatList, Text, TouchableOpacity } from 'react-native'
import ProgressView from './utils/Progress';
import Grid from '@mui/material/Grid';
import { useAuthContext } from './containers/AuthContextProvider';
import { useUserContext } from './containers/UserContextProvider';
import { Alert } from './utils/Alert.web';
import { useSettingsContext } from './containers/SettingsContextProvider';

export default function SelectCasino() {

	const { claims, logOut } = useAuthContext()
	const { userDoc } = useUserContext()
	const { setLinkedCasinoId } = useSettingsContext()
	const [showLoader, setShowLoader] = useState(false)
	const rolesObject = useMemo(() => {
		return claims?.roles ?? {}
	}, [claims])
	const [casinos, setCasinos] = useState<Casinos.Casino[]>([])

	const roles = useMemo(() => {
		const keys = Object.keys(rolesObject)
		const __roles = keys.filter((key) => {
			const role = rolesObject[key] as Users.Role
			return role.type === 'casino'
		})
			.map((key) => rolesObject[key])
		return __roles
	}, [rolesObject])

	useEffect(() => {
		if (rolesObject && (['admin', 'owner'].includes(rolesObject['account']?.level))) {
			getCasinos()
		}
	}, [rolesObject])

	async function getCasinos() {
		setShowLoader(true)
		try {
			const response = await Casinos.getCasinos({
				orderBy: {
					fieldPath: 'name',
					directionStr: 'asc'
				},
				limit: 1000
			})
			console.log('Response', response.data)
			setCasinos(response.data)
		} catch (error) {
			console.log('Error Fetching Casinos : ', error)
			Alert.alert('Error Fetching Casinos', String(error))
		}
		setShowLoader(false)
	}

	return (
		<Grid style={{
			alignSelf: 'center',
			padding: 10
		}} container xs={8} sm={7} md={6} lg={5} xl={4}>
			<View style={{
				flex: 1,
			}}>
				{showLoader == true && <ProgressView />}
				<Text style={{
					textAlign: 'center',
					fontSize: 60,
					fontWeight: '200',
					marginVertical: 50
				}}>InfiGifts</Text>
				<Text style={{
					fontSize: 28,
					textAlign: 'left',
					fontWeight: '200',
					marginBottom: 15
				}}>Hello {userDoc.displayName}, please choose the casino</Text>
				{showLoader === false && (
					<div>
						{casinos.length > 0 ? (
							<FlatList
								data={casinos}
								ListEmptyComponent={renderEmptyList}
								renderItem={renderCasino} />
						) : (
							<FlatList
								data={roles}
								ListEmptyComponent={renderEmptyList}
								renderItem={renderItem} />
						)}
					</div>
				)}
				<button style={{
					marginTop: 10
				}} onClick={() => {
					logOut()
				}}>Logout</button>
			</View>
		</Grid>
	)

	function renderEmptyList() {
		return (
			<View style={{
				borderWidth: 1,
				borderColor: '#dddddd',
				padding: 30
			}}>
				<Text style={{
					textAlign: 'center'
				}}>You do not have access to any casinos. If you think this is a problem, please contact the desired casino administrator</Text>
			</View>
		)
	}

	function renderItem({ item }: { item: Users.Role }) {
		return (
			<TouchableOpacity
				onPress={() => onSelectCasino(item)}
				style={{
					borderWidth: 1,
					paddingVertical: 25,
					paddingHorizontal: 20,
					borderColor: '#dddddd',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<Text style={{
					fontSize: 18
				}}>{item.name}</Text>
				<Text style={{
					fontSize: 20
				}}>→</Text>
			</TouchableOpacity>
		)
	}

	function renderCasino({ item }: { item: Casinos.Casino }) {
		return (
			<TouchableOpacity
				onPress={() => {
					setLinkedCasinoId(item.id)
				}}
				style={{
					borderWidth: 1,
					paddingVertical: 25,
					paddingHorizontal: 20,
					borderColor: '#dddddd',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<Text style={{
					fontSize: 18
				}}>{item.name}</Text>
				<Text style={{
					fontSize: 20
				}}>→</Text>
			</TouchableOpacity>
		)
	}

	function onSelectCasino(role: Users.Role) {
		setLinkedCasinoId(role.id)
	}
}
