import { View } from "react-native";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import SelectCasino from "./SelectCasino";
import ResetPassword from "./ResetPassword";
import SettingsContextProvider, { useSettingsContext } from './containers/SettingsContextProvider'
import AuthContextProvider, { useAuthContext } from "./containers/AuthContextProvider";
import {
	BrowserRouter as Router
} from 'react-router-dom';
import UserContextProvider from "./containers/UserContextProvider";


function App() {

	const { user } = useAuthContext()
	const { linkedCasinoId } = useSettingsContext();

	return (
		<View>
			{user == null ? (
				<Switch>
					<Route exact path="/" component={Login} />
					<Route path="/reset-password" component={ResetPassword} />
				</Switch>
			) : (
				<Switch>
					{linkedCasinoId == null ? (
						<Route path="/" component={SelectCasino} />
					) : (
						<Route path="/" component={Home} />
					)}
				</Switch>
			)}
		</View>
	);
}

const AppContainer = () => (
	<Router>
		<SettingsContextProvider>
			<AuthContextProvider>
				<UserContextProvider>
					<App />
				</UserContextProvider>
			</AuthContextProvider>
		</SettingsContextProvider>
	</Router>
)

export default AppContainer