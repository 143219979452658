import { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {
	Casinos
} from 'cs-connect';
import {
	View
} from 'react-native'
import { useCasinoContext } from './containers/CasinoContextProvider';

export default function Settings() {

	const [showLoader, setShowLoader] = useState(false)
	const { casino } = useCasinoContext()
	const [accessKeys, setAccessKeys] = useState<any[]>([])

	useEffect(() => {
		Casinos.getAccessKeys(casino.id)
			.then((response) => {
				console.log('Access Keys', response)
				setAccessKeys(response.data)
			})
			.catch((error) => {
				console.log('Error Getting Access Keys: ', error)
			})
	}, [])

	if (showLoader === true) {
		return <progress />
	}

	return (
		<Paper style={{
			marginTop: 20
		}} elevation={0} variant='outlined'>
			<View style={{
				padding: 20,
				width: 700
			}}>
				<div style={{
					display: 'flex',
					flex: 1,
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: 15,
				}}>
					<b>Access Keys</b>
					<Button onClick={onClickCreate}>Create</Button>
				</div>
				{accessKeys.map((accessKey) => (
					<Paper variant='outlined' style={{
						display: 'flex',
						flex: 1,
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 15,
					}}>
						<label>Created On</label>
						<p>{accessKey.createdAt?.toDate().toString()}</p>
					</Paper>
				))}
			</View>
		</Paper>
	)

	async function onClickCreate() {

		setShowLoader(true)
		try {
			if (casino.id == null) throw new Error("Casino ID Not Found");
			const response = await Casinos.createAccessKey(casino.id, {})
			setAccessKeys([...accessKeys, response.id])
		} catch (error) {
			console.log('Error Creating Access Key ', error)
		}
		setShowLoader(false)
	}
}
