import React, { useEffect, useRef, useState } from "react";
import { Members } from "cs-connect";
import {
    Text,
    View
} from 'react-native'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import Button from './components/Button'
import { CustomColumn, modifiers } from "./MembersList";
import { convertToTypeOf, useDebounce } from "./utils";
import { getAllKeys } from 'firestar/lib/Utils'
import { Grid } from "@material-ui/core";

export default function Member(props: {
    member?: Members.Member
    onClose: () => void;
    columns: CustomColumn[];
    onUpdate: (member: Members.Member) => void;
    onAdd: (member: Members.Member) => void;
}) {

    const { columns, onClose, onUpdate, onAdd } = props;

    const [member, setMember] = useState<Members.Member>(cloneDeep(props.member ?? {}) as Members.Member)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
	const errorFieldsRef = useRef({})
	const debouncedMember = useDebounce(member, 300)
	const [enableSaveButton, setEnableSaveButton] = useState(false)

	useEffect(() => {
		if (props.member == null) {
			const __member = {} as Members.Member
			columns.forEach((c) => {
				if (c.initialEditValue != null && c.field) {
					set(__member, c.field?.toString(), c.initialEditValue)
				}
			})
			setMember(__member)
		}
	}, [])

	useEffect(() => {
		if (debouncedMember) {
			console.log('Debounced Member', debouncedMember)
			errorFieldsRef.current = {}
			columns.forEach((c) => {
				const isValid = c.validate?.(debouncedMember) ?? true
				if (c.field) {
					if (isValid) {
						delete errorFieldsRef.current[c.field?.toString()]
					} else {
						errorFieldsRef.current[c.field as string] = false
					}
				}
			})
			setEnableSaveButton(Object.keys(errorFieldsRef.current).length === 0)
		}
	}, [debouncedMember, columns])

	return (
		<Grid md={10}>
			<View style={{
				backgroundColor: 'white',
				alignSelf: 'center',
				overflow: 'hidden',
				borderRadius: 5,
				padding: 10
			}}>
				<View style={{
					height: 60,
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#dddddd'
				}}>
					<h2>{props.member == null ? 'Add Member' : 'Edit Member'}</h2>
				</View>
				<View style={{
					flex: 1,
					flexDirection: 'column',
					flexWrap: 'wrap',
					overflow: "scroll",
					alignContent: 'flex-start',
					padding: '1em',
					height: '80vh',
				}}>
					{columns.map((c) => {

						const disabled = (c.editable === 'never')

						const lookup = (c.selectionLookUp?.(member) ?? c.lookup) as { [key: string]: any }
						const isValid = errorFieldsRef.current[c.field as string] ?? true

						const hidden = c.hidden ?? false

						const value = get(member, c.field as string)

						if (hidden === true) {
							return <View />
						}

						if (c.render) {
							<View style={{
								width: 500,
								marginBottom: 20,
								marginRight: 25
							}}>
								{c.render?.(member, 'row')}
							</View>
						}

						return (
							<View style={{
								width: 500,
								marginBottom: 20,
								marginRight: 50
							}}>
								<b><label>{c.title}</label></b>
								<View style={{
									flex: 1
								}}>
									{(c.type === 'string' || c.type === 'numeric') && (
										<div style={{
											display: 'flex',
											flex: 1,
										}}>
											{lookup == null ? (
												<input
													placeholder={c.editPlaceholder}
													name={c.field?.toString()}
													value={value}
													type={c.fieldType}
													onChange={(event) => onChangeValue(event.target.name, event.target.value)}
													onBlur={(event) => {
														let value = event.target.value
														if (c.formatValue) {
															value = c.formatValue(value)
														}

														value = convertToTypeOf(c.type, value)

														onChangeValue(c.field as string, value)
													}}
													disabled={disabled}
													style={{
														height: 50,
														marginTop: 1,
														paddingLeft: '1em',
														fontSize: 16,
														flex: 1,
														border: `1px solid ${isValid === false ? 'red' : '#dddddd'}`
													}} />
											) : (
												<select
													style={{
														marginTop: 1,
														padding: '1em',
														fontSize: 16,
														flex: 1,
														border: `1px solid ${isValid === false ? 'red' : '#dddddd'}`
													}}
													disabled={disabled}
													onChange={(event) => onChangeValue(event.target.name, event.target.value)}
													value={value}
													name={c.field?.toString()}
													id={c.field?.toString()}>
													{Object.keys(lookup).map((key) => (
														<option value={key}>{lookup[key]}</option>
													))}
												</select>
											)}
										</div>
									)}
									{c.type === 'boolean' && (
										<input
											type={'checkbox'}
											name={c.field as string}
											placeholder={c.editPlaceholder}
											checked={value}
											onChange={(event) => onChangeValue(event.target.name, event.target.checked)}
											disabled={disabled}
											style={{
												display: 'flex',
												alignSelf: 'start',
												marginTop: 20,
												paddingLeft: '1em',
												fontSize: 16
											}} />
									)}
								</View>
							</View>
						)
					})}
				</View>
				{showErrorMessage && Object.keys(errorFieldsRef.current).length > 0 && <Text style={{
					textAlign: 'center',
					color: 'red'
				}}>There seems to be errors in the form. Please fix them to save the member.</Text>}
				<View style={{
					flexDirection: 'row',
					justifyContent: 'center'
				}}>
					<Button
						type='outlined'
						style={{
							width: 200,
							borderRadius: 5,
							margin: 10
						}}
						onPress={() => onClose()}
						title='Cancel' />
					<Button
						style={{
							width: 200,
							borderRadius: 5,
							margin: 10
						}}
						disabled={!enableSaveButton}
						onPress={onPressSave}
						title='Save' />
				</View>
			</View>
		</Grid>
	)

    function onPressSave(){

        if (Object.keys(errorFieldsRef.current).length > 0) {
            setShowErrorMessage(true)
        } else {
            props.member ? onUpdate(member) : onAdd(member)
        }
    }

    function onChangeValue(path: string, value: any){
		
        const __member = {...member}
        set(__member, path, value)

		const modifier = modifiers[path]
		if(modifier){
			const modified = modifier(__member)
			const mofifiedKeys = getAllKeys(modified)
			mofifiedKeys.forEach((key) => {
				const value = get(modified, key)
				set(__member, key, value)
			})
		}
		
        setMember(__member)
    }
}
