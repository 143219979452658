import { Users } from "cs-connect";
import { RoleIdOptions } from "cs-connect/lib/Users";
import React from "react";
import { useCasinoContext } from "../containers/CasinoContextProvider";

interface Props {
	minimumRole: Users.RoleId;
	children: React.ReactNode,
	type?: 'hide' | 'disable' | 'fade'
}

export default function CheckPermission(props: Props) {
	const { role } = useCasinoContext()
	const showChildren = isAuthorized(props.minimumRole, role)
	const type = props.type ?? 'hide'

	if (showChildren) return <>{props.children}</>

	if (type == 'hide') return <div></div>

	return (
		<div style={{
			pointerEvents: (type == 'disable' || type == 'fade') ? 'none' : undefined,
			opacity: (type == 'disable' || type == 'fade') ? 0.7 : 1,
		}}>
			{props.children}
		</div>
	);
}

export function isAuthorized(minimumRole: Users.RoleId, role: Users.Role | null) {
	const roleIds = Object.keys(RoleIdOptions);
	const minimumRoleIndex = roleIds.findIndex((r) => r === minimumRole);
	const currentRoleIndex = roleIds.findIndex((r) => r === role?.level);
	return currentRoleIndex <= minimumRoleIndex;
}
