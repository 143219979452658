import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import ProgressView from '../components/Progress';

type SettingsContextType = {
	linkedCasinoId?: string,
	setLinkedCasinoId: (value?: string) => void
}

const SettingsContext = createContext({

} as SettingsContextType)

export const useSettingsContext = () => useContext(SettingsContext);

export default function SettingsContextProvider(props: {
	children: React.ReactNode
}) {

	const [linkedCasinoId, setLinkedCasinoId] = useState<string>()
	const [showLoader, setShowLoader] = useState(true)

	const settings = useMemo<SettingsContextType>(() => {
		return {
			linkedCasinoId,
			setLinkedCasinoId
		}
	}, [linkedCasinoId])

	useEffect(() => {
		const value = localStorage.getItem('settings')
		if (value) {
			const settings = JSON.parse(value) as SettingsContextType
			setLinkedCasinoId(settings.linkedCasinoId)
		}
		setShowLoader(false)
	}, [])

	useEffect(() => {
		localStorage.setItem('settings', JSON.stringify(settings))
	}, [settings])

	if (showLoader) { return <ProgressView title='Initializing Settings' /> }

	return (
		<SettingsContext.Provider value={{
			linkedCasinoId,
			setLinkedCasinoId
		}}>
			<>{props.children}</>
		</SettingsContext.Provider>
	)
}