import { Casinos, Users } from 'cs-connect';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import Progress from '../utils/Progress';
import { useSettingsContext } from './SettingsContextProvider';
import { useAuthContext } from './AuthContextProvider';

type CasinoContextProps = {
	casino: Casinos.Casino,
	role: Users.Role
}

const CasinoContext = createContext<CasinoContextProps>({

} as unknown as CasinoContextProps)

export const useCasinoContext = () => useContext(CasinoContext);

export default function CasinoContextProvider(props: {
	children
}) {

	const { linkedCasinoId } = useSettingsContext()
	const { claims } = useAuthContext()
	const [casino, setCasino] = useState<Casinos.Casino>()

	const role = useMemo(() => {
		return (claims.roles ?? {})[linkedCasinoId ?? '']
	}, [linkedCasinoId, claims])

	useEffect(() => {
		if (linkedCasinoId) {
			getCasino(linkedCasinoId)
		}
	}, [linkedCasinoId])

	if (casino == null) {
		return <Progress title='Fetching Casino...' />
	}

	return (
		<>
			<CasinoContext.Provider value={{
				casino,
				role
			}}>
				{props.children}
			</CasinoContext.Provider>
		</>
	)

	async function getCasino(casinoId: string) {
		const response = await Casinos.getCasinoWithId(casinoId, { source: 'server' })
		setCasino(response)
	}
}
