import React, { createContext, useContext, useState } from 'react'
import { Promotions } from 'cs-connect';
import { useCasinoContext } from './CasinoContextProvider';

type PromotionsContextProps = {
	promotions: Promotions.Promotion[],
	fetchPromotions: () => Promise<Promotions.Promotion[]>,
	deletePromotion: (promotionId: string) => Promise<void>,
	fetchPromotion: (promotionId: string) => Promise<Promotions.Promotion>,
	addPromotion: (document: Promotions.Promotion) => Promise<Promotions.Promotion>,
	updatePromotion: (promotionId: string, document: Promotions.Promotion | Partial<Promotions.Promotion>) => Promise<Promotions.Promotion | Partial<Promotions.Promotion>>
}

const PromotionsContext = createContext<PromotionsContextProps>({
	promotions: [],
} as unknown as PromotionsContextProps)

export const usePromotionsContext = () => useContext(PromotionsContext);

const PromotionsProvider = (props:{
	children
}) => {

	const [promotions, setPromotions] = useState<Promotions.Promotion[]>([])
	const { casino } = useCasinoContext()
	const { children } = props

	return (
		<PromotionsContext.Provider value={{
			promotions,
			fetchPromotions,
			deletePromotion,
			fetchPromotion,
			addPromotion,
			updatePromotion
		}}>
			<>{children}</>
		</PromotionsContext.Provider>
	)

	function fetchPromotion(promotionId: string) {
		return new Promise<Promotions.Promotion>(async (resolve, reject) => {
			try {
				const response = await Promotions.getPromotion(casino.id, promotionId)
				resolve(response)
			} catch (error) {
				reject(error)
			}
		})
	}

	function fetchPromotions() {
		return new Promise<Promotions.Promotion[]>(async (resolve, reject) => {
			try {
				const response = await Promotions.getPromotions(casino.id, {
					orderBy: {
						fieldPath: 'updatedAt',
						directionStr: 'desc'
					},
					limit: 150
				})
				setPromotions(response.data)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	function deletePromotion(promotionId: string) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await Promotions.deletePromotion(casino.id, promotionId)
				const __promotions = [...promotions]
				const deleteIndex = __promotions.findIndex((p) => p.id === promotionId)
				if (deleteIndex >= 0) {
					__promotions.splice(deleteIndex, 1)
				}
				setPromotions(__promotions)
				resolve()
			} catch (error) {
				reject(error)
			}
		})
	}

	function addPromotion(document: Promotions.Promotion) {
		return new Promise<Promotions.Promotion>(async (resolve, reject) => {
			try {

				const response = await Promotions.addPromotion(casino.id, document)
				resolve(response)
			} catch (error) {
				reject(error)
			}
		})
	}

	function updatePromotion(promotionId: string, document: Promotions.Promotion | Partial<Promotions.Promotion>) {
		return new Promise<Promotions.Promotion | Partial<Promotions.Promotion>>(async (resolve, reject) => {
			try {

				const response = await Promotions.updatePromotion(
					casino.id,
					promotionId,
					document
				)
				resolve(response)

			} catch (error) {
				reject(error)
			}
		})
	}
}

export default PromotionsProvider