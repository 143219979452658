import React, { useState } from 'react'
import { View, Text } from 'react-native'
import {
	Link,
	useHistory
} from 'react-router-dom'
import Button from './components/Button'
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UsersIcon from '@mui/icons-material/VpnKey';
import SettingsIcon from '@mui/icons-material/Settings';
import MembersIcon from '@mui/icons-material/CardGiftcard';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import Main from './Main';
import Progress from './utils/Progress.web';
import { useSettingsContext } from './containers/SettingsContextProvider';
import { useAuthContext } from './containers/AuthContextProvider';
import CasinoContextProvider, { useCasinoContext } from './containers/CasinoContextProvider';

const menuItems = [
	{
		id: '/dashboard',
		title: 'Dashboard',
		icon: () => <DashboardIcon />
	},
	{
		id: '/promotions',
		title: 'Promotions',
		icon: () => <MembersIcon />
	},
	{
		id: '/users',
		title: 'Users & Roles',
		icon: () => <UsersIcon />
	},
	{
		id: '/settings',
		title: 'Settings',
		icon: () => <SettingsIcon />
	}
]

function Home() {

	const [showMenu, setShowMenu] = useState(false)
	const { logOut } = useAuthContext()
	const { setLinkedCasinoId } = useSettingsContext()
	const { casino } = useCasinoContext()
	const [showLoader, setShowLoader] = useState(false)
	const { user } = useAuthContext()
	const history = useHistory()
	
	if (showLoader === true) {
		return <Progress />
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar>
					<View style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						flex: 1
					}}>
						<View style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center'
						}}>
							<IconButton
								onClick={toggleMenu}
								size="large"
								edge="start"
								color="inherit"
								sx={{ mr: 2 }}>
								<MenuIcon />
							</IconButton>
							<Text style={{
								color: 'white',
								fontSize: 20
							}}>{casino?.name}</Text>
							<IconButton style={{
								marginLeft: 5
							}} onClick={onPressUnlink}>
								<LogoutIcon htmlColor='white' />
							</IconButton>
						</View>
						<View style={{
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}>
							<div>
								<div>{user.displayName}</div>
								<div>{user.email}</div>
							</div>
							&emsp;
							<button onClick={onClickLogout}>Logout</button>
						</View>
					</View>
				</Toolbar>
				<Drawer
					anchor={'left'}
					open={showMenu}
					onClose={toggleMenu}>
					{menuItems.map(({ icon, id, title }) => (
						<Link to={id} onClick={toggleMenu} style={{
							textDecoration: 'none'
						}}>
							<View style={{
								paddingHorizontal: 55,
								paddingLeft: 25,
								paddingVertical: 15,
								borderBottomWidth: 1,
								borderColor: '#dddddd',
								flexDirection: 'row',
								alignItems: 'center',
							}}>
								{icon()}
								<Text style={{
									marginLeft: 15
								}}>{title}</Text>
							</View>
						</Link>
					))}
				</Drawer>
			</AppBar>
			<View style={{
				padding: 15
			}}>
				<Main />
			</View>
		</Box>
	)

	function toggleMenu() {
		setShowMenu(!showMenu)
	}

	function onPressUnlink() {
		setLinkedCasinoId(undefined)
		history.replace('/')
	}

	function onClickLogout() {
		logOut()
	}
}

const HomeContainer = () => (
	<CasinoContextProvider>
		<Home />
	</CasinoContextProvider>
)

export default HomeContainer
